import React from 'react';
import App from './App';
import 'antd/dist/antd.min.css';
import './global.scss';
import "@ant-design/flowchart/dist/index.css";
import 'moment/locale/vi'; // without this line it didn't work

import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import moment from 'moment';
moment.locale("vi");
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

serviceWorker.unregister();
