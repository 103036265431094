import { Menu } from "modules/admin-authorization/utils/authorization.types";
import { AdminManager } from "modules/admin-manager/utils/admin-manager.types";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import RouteConfigs from "routing/config.route";
import { Subscription } from "rxjs";
import { Admin } from "types/common.types";
import { IRoute } from "types/Route";
import { AsyncStorageUtils, StorageKey } from "utils/async-storage.utils";
import { EventBusName } from "utils/event-bus";
import EventBus from "utils/event-bus/event-bus";

interface IPermissionContext {
    routes: IRoute[];
}

const PermissionContext = createContext<IPermissionContext>({
    routes: [],
});
  
export const usePermission = () => useContext(PermissionContext);
  
const PermissionProvider = (props: any) => {
    const [routes, setRoutes] = useState<IRoute[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);

    const subscriptions = useRef<any>();
    
    useEffect(() => {
        registerEventBus();

        return () => {
            unregisterEventBus();
        };
    }, []);



    const registerEventBus = () => {
        subscriptions.current = new Subscription();
        subscriptions.current.add(
            EventBus.getInstance().events.subscribe((data: any) => {
                switch (data.type) {
                    case EventBusName.ON_LOGIN:
                        setRefresh(prev => !prev);
                        break;
                    default:
                        break;
                }
            })
        );
    };

    const unregisterEventBus = () => {
        subscriptions.current?.unsubscribe();
    };
    
    useEffect(() => {
        const admin: AdminManager | null = AsyncStorageUtils.getObject(StorageKey.USER);
        let menus: Menu[] = [];
        if (admin?.role === "ADMIN") {
            setRoutes(RouteConfigs);
            return;
        }
        if (admin) {
            admin.roles?.forEach(i => {
                menus = [...menus, ...i.menus];
            });
        }

        menus = menus.filter((v,i) => menus.indexOf(v) === i);
    
        const menusCode = [...menus.map(i => i.code), "default"];
    
        const newRoutes = RouteConfigs.map(e => {
            if (e.name === "menu_home") {
                return {
                    ...e,
                    routes: e.routes?.filter(i => menusCode.includes(i.code || "")).map(i => {
                        return {
                            ...i,
                            routes: i.routes?.filter(i => menusCode.includes(i.code || ""))
                        };
                    })
                };
            }
            return e;
        });
        setRoutes(newRoutes);
    }, [refresh]);
    

    return (
        <PermissionContext.Provider value={{routes}}>
            {props.children}
        </PermissionContext.Provider>
    );
};
  
  export default React.memo(PermissionProvider);