import { CalendarOutlined, ReconciliationOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import React, { lazy } from 'react'
import { IRoute } from 'types/Route'

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));
const BookingScreen = lazy(() => import('modules/booking/booking.screen'))
const ServiceScreen = lazy(() => import('modules/service/service.screen'))

export const Service_Route: IRoute = {
    path: '/service',
    name: 'service.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <ReconciliationOutlined />,
    code: 'service',
    routes: [
        {
            path: '/service/management',
            name: 'service.list_service',
            component: ServiceScreen,
            exact: true,
            code: 'service.service'
        },
        {
            path: '/service/booking',
            name: 'booking.menu',
            component: BookingScreen,
            exact: true,
            code: 'service.booking'
        }

    ]
}