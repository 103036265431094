import MessagesRoute from './routes/messages.route'
import WarehouseRoute from './routes/warehouse.route'
import { HomeOutlined } from '@ant-design/icons'
import React, { lazy } from 'react'
import { IRoute } from 'types/Route'
import { PATH_HOME } from '.'
import * as routes from './routes'
import CollectionRoute from './routes/collection.route'
import { CouponRoute } from './routes/coupon.route'
import SalesRoute from './routes/sales.route'
import RankRoute from './routes/rank.route'
import ReportRoute from './routes/report.route'
import AuthorizationRoute from './routes/authorization.route'
import PaymentRoute from './routes/payment.route'
import { SettingRoute } from './routes'
import { TaskRoute } from './routes/task.router'
import { HRMRoute } from './routes/personnel_manager.router'
import { langding_page_Route } from './routes/landing_page'
import { importHtml_Route } from './routes/import_html'
import { Service_Route } from './routes/service.route'

const MainLayout = lazy(() => import('../libraries/layouts/main.layout'))
const HomeScreen = lazy(() => import('../modules/home/home.screen'))

const RouteConfigs: IRoute[] = [
    routes.AuthRoute,
    {

        path: PATH_HOME,
        name: 'menu_home',
        component: MainLayout,
        hideInMenu: true,
        code: 'default',
        routes: [
            {
                path: PATH_HOME,
                name: 'menu_home',
                icon: <HomeOutlined />,
                exact: true,
                component: HomeScreen,
                code: 'default'
            },
            routes.OrderManagerRoute,
            WarehouseRoute,
            // MessagesRoute,
            routes.AccountManagerRoute,
            routes.ProductRoute,
            TaskRoute,
            HRMRoute,
            langding_page_Route,
            // SaleRoute,
            SalesRoute,
            // CollectionRoute,
            CouponRoute,
            // RankRoute,
            routes.FaqRoute,
            // routes.FooterRoute,
            // routes.BannerRoute
            AuthorizationRoute,
            importHtml_Route,
            Service_Route,
            // ReportRoute,
            //Thanh toán
            // PaymentRoute,
            //Hiện tin nhắn trực tiếp
            // MessagesRoute,
            SettingRoute
        ]
    }
]

export default RouteConfigs
