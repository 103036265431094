/* 
  Created by develop_tu at 03-21-2022 21:32:57
  Module product
*/
import { CalendarOutlined, ReconciliationOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import React, { lazy } from 'react'
import { IRoute } from 'types/Route'

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));
const importHtmlScreen = lazy(() => import('modules/importHtml/import_html.screnn'))

export const importHtml_Route: IRoute = {
    path: '/Html',
    name: 'Html.home',
    existSubMenu: true,
    component: ContentLayout,
    icon: <ReconciliationOutlined />,
    code: 'Html',
    routes: [
        {
            path: '/Html/listHtml',
            name: 'Html.import_html',
            component: importHtmlScreen,
            exact: true,
            code: 'importHtml'
        },
    ]
}
