import { default as React, Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { setI18nConfig } from 'res/languages';
import MasterRoutes from 'routing/master.route';
import RouteConfigs from 'routing/config.route';
import BaseConfirmModal from 'libraries/modal/BaseConfirmModal';
import PermissionProvider, { usePermission } from 'services/provider/PermissionProvider';

setI18nConfig();

const App: React.FC<any> = () => {
    return (
        <Fragment>
            <PermissionProvider>
                <RouterApp/>
            </PermissionProvider>

            <BaseConfirmModal />
        </Fragment>
    );
};

const RouterApp = () => {
    const {routes} = usePermission();
    return (
        <>
            {/* @ts-ignore */}
            <Router>
                <MasterRoutes routes={routes} />
            </Router>
        </>
    );
};

export default App;
