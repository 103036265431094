/* 
  Created by develop_tu at 03-21-2022 21:32:57
  Module product
*/
import { CalendarOutlined } from '@ant-design/icons'
// import carWorkScreen from 'modules/task/Task-Car-Work/car_work.screen'
import React, { lazy } from 'react'
import { IRoute } from 'types/Route'

const TaskScreen = lazy(() => import('modules/task/task.screen'))
const TaskDetailAssignScreen = lazy(() => import('modules/task/task-detail.screen'))
const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'))
const carWorkScreen = lazy(() => import('modules/Task-Car-Work/car_work.screen'))

export const TaskRoute: IRoute = {
    path: '/task',
    name: 'task.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <CalendarOutlined />,
    code: 'task',
    routes: [
        {
            path: '/task/list',
            name: 'task.listTask',
            component: TaskScreen,
            exact: true,
            code: 'task'
        },
        {
            path: '/task/add',
            name: 'task.textAdd',
            component: TaskDetailAssignScreen,
            hideInMenu: true,
            exact: true,
            code: 'task'
        },

        {
            path: '/task/detail_task/:id',
            name: 'task.detail_work',
            component: TaskDetailAssignScreen,
            hideInMenu: true,
            exact: true,
            code: 'task'
        },
        {
            path: '/task/car_work',
            name: 'car_work.production_plan',
            component: carWorkScreen,
            exact: true,
            code: 'task'
        },

    ]
}
