/* 
  Created by develop_tu at 03-21-2022 21:32:57
  Module product
*/
import { ShopOutlined } from '@ant-design/icons'
import React, { lazy } from 'react'
import { IRoute } from 'types/Route'

const ProductScreen = lazy(() => import('modules/product/product.screen'))
const ProductViewedScreen = lazy(() => import('modules/product/product-viewed.screen'))
const ProductDetailScreen = lazy(() => import('modules/product/product-detail.screen'))
const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'))
const CategoryScreen = lazy(() => import('modules/category/category.screen'))

// const PropertyScreen = lazy(() => ('modules/pr'))
const PropertyScreen = lazy(() => import('modules/property/property.screen'))


const HomeProductScreen = lazy(() => import('modules/home-product/home-product.screen'))

export const ProductRoute: IRoute = {
    path: '/product',
    name: 'product.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <ShopOutlined />,
    code: 'product',
    routes: [
        {
            path: '/product/list',
            name: 'product.title',
            component: ProductScreen,
            exact: true,
            code: 'product.product'
        },
        {
            path: '/product/add',
            name: 'product.textAdd',
            component: ProductDetailScreen,
            hideInMenu: true,
            exact: true,
            code: 'product.product'
        },
        {
            path: '/product/category',
            name: 'category.menu',
            component: CategoryScreen,
            exact: true,
            code: 'product.category'
        },
        {
            path: '/product/property_category',
            name: 'property.routerName',
            component: PropertyScreen,
            exact: true,
            code: 'product.property'
        },
        {
            path: '/product/edit/:id',
            name: 'product.textDetail',
            component: ProductDetailScreen,
            hideInMenu: true,
            exact: true,
            strict: true,
            code: 'product.product'
        },
        {
            path: '/product/viewed/:id',
            name: 'product.viewedByCustomers',
            component: ProductViewedScreen,
            exact: true,
            strict: true,
            hideInMenu: true,
            code: 'product.product'
        },
        {
            path: '/product/home-product',
            name: 'homeProduct.menu',
            component: HomeProductScreen,
            exact: true,
            code: 'product.home'
        }
    ]
}
