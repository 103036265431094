import { UserOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';

const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'));
const AdminAuthorizationScreen = lazy(() => import('../../modules/admin-authorization/admin-authorization.screen'));

const AuthorizationRoute: IRoute = {
    path: '/authorization',
    name: 'admin_authorization',
    component: ContentLayout,
    icon: <UserOutlined />,
    existSubMenu: true,
    code: 'permission',
    routes: [
        {
            path: '/authorization/admin',
            name: 'admin_role',
            component: AdminAuthorizationScreen,
            exact: true,
            code: 'permission.role',
        },
    ]
};
export default AuthorizationRoute;