/* 
  Created by develop_tu at 03-21-2022 21:32:57
  Module product
*/
import { CalendarOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import React, { lazy } from 'react'
import { IRoute } from 'types/Route'

const TimeKeepingScreen = lazy(() => import('modules/personnel-manager(HRM)/timeKeeping.screen'));
const HRMScreen = lazy(() => import('modules/personnel-manager(HRM)/HRM.screen'));
const ContentLayout = lazy(() => import('../../libraries/layouts/content.layout'))
export const HRMRoute: IRoute = {
    path: '/HRM',
    name: 'hrm.menu',
    existSubMenu: true,
    component: ContentLayout,
    icon: <UsergroupAddOutlined />,
    code: 'HRM',
    routes: [
        {
            path: '/HRM/list',
            name: 'hrm.listHrm',
            component: HRMScreen,
            exact: true,
            code: 'HRM'
        },
        {
            path: '/HRM/timekeeping-list',
            name: 'tiemkeeping.listTimeKeeping',
            component: TimeKeepingScreen,
            exact: true,
            code: 'timekeeping'
        },
        // {
        //     path: '/task/add',
        //     name: 'task.textAdd',
        //     component: TaskDetailAssignScreen,
        //     hideInMenu: true,
        //     exact: true,
        //     code: 'task'
        // },

        // {
        //     path: '/task/detail_task/:id',
        //     name: 'task.detail_work',
        //     component: TaskDetailAssignScreen,
        //     hideInMenu: true,
        //     exact: true,
        //     code: 'task'
        // },
    ]
}
