import { SettingOutlined } from '@ant-design/icons';
import { lazy } from 'react';
import { IRoute } from 'routing/route.types';
import React from 'react';

const SettingScreen = lazy(() => import('../../modules/settings/settings.screen'));

export const SettingRoute: IRoute = {
    path: '/settings',
    name: 'menu_setting',
    icon: <SettingOutlined />,
    exact: true,
    component: SettingScreen,
    code: "config",
};
